@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700&display=swap);
/**
 * File with settings. A setting is a scss variable for example.
 * All variables are made available by webpack (sass-resource-loader). There is no need to import this file in every scss or vue file.
 *
 * WARNING:
 * Do not include anything that will be actually rendered in CSS, because it will be added to every imported SASS file.
 */
/**
 * iotaCSS Core Settings
 * @url https://www.iotacss.com/docs/settings/core/
 */
body {
  background: #fff;
  color: #1d1d1b;
  font-family: "Montserrat", serif;
  font-size: 18px;
  margin: 0;
  min-width: 320px;
  padding: 0; }

body,
html {
  height: 100%; }

hr {
  border: 0 none;
  border-bottom: 1px solid #e5e5e5;
  margin: 0 0 15px 0; }

ul,
ol {
  margin: 0 0 10px 20px;
  padding: 0 0 0 0; }

button {
  font-family: "Montserrat", serif;
  outline: none; }

::placeholder {
  color: #cecece; }

@media screen {
  :focus {
    outline: none; } }

.input-with-select .el-input-group__prepend {
  background-color: #fff; }

.input-with-select .el-select .el-input {
  width: 150px !important; }

.c-input {
  border-color: #e5e5e5;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  font-size: inherit;
  height: 40px;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%; }
  .c-input:hover {
    border-color: #c1c1c1; }
  .c-input:focus {
    border-color: #fff; }
  .c-input--danger {
    border-color: #e7282b !important; }

table {
  border-collapse: collapse;
  border-spacing: 0; }
  table tr td {
    font-size: 18px;
    vertical-align: top; }

a:link, a:hover, a:active, a:visited {
  color: #2177b9;
  outline: 0;
  text-decoration: none;
  cursor: pointer; }

a:hover {
  color: #5ea9e2; }

p {
  margin: 0 0 0 0;
  padding: 0 0 15px 0;
  font-size: 18px;
  line-height: 22px; }
  p:last-child {
    padding-bottom: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px 0; }

h1 {
  color: #1d1d1b;
  font-family: "Montserrat", serif;
  font-size: 24px;
  font-weight: 700; }

h2 {
  color: #1d1d1b;
  font-family: "Montserrat", serif;
  font-size: 22px;
  font-weight: 700; }

h3 {
  color: #1d1d1b;
  font-family: "Montserrat", serif;
  font-size: 20px;
  font-weight: 700; }

h4 {
  color: #1d1d1b;
  font-family: "Montserrat", serif;
  font-size: 18px;
  font-weight: 700; }

h5 {
  color: #1d1d1b;
  font-family: "Montserrat", serif;
  font-size: 16px;
  font-weight: 700; }

h6 {
  color: #1d1d1b;
  font-family: "Montserrat", serif;
  font-size: 14px;
  font-weight: 700; }

.tile {
  padding-bottom: 15px;
  text-align: center; }
  .tile__icon {
    min-height: 70px;
    background-color: #2177b9;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    padding: 15px;
    font-size: 30px;
    color: #fff;
    transition: box-shadow 250ms; }
    .tile__icon i,
    .tile__icon span {
      transition: text-shadow 250ms;
      text-shadow: 1px 1px rgba(29, 29, 27, 0.4); }
  .tile:hover .tile__icon {
    color: white;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15), 0 6px 10px rgba(0, 0, 0, 0.15); }
    .tile:hover .tile__icon i,
    .tile:hover .tile__icon span {
      text-shadow: 2px 2px rgba(29, 29, 27, 0.4); }
  .tile__title {
    padding: 15px 0 15px 0;
    font-size: 16px; }

.successful-visual {
  text-align: center;
  width: 400px;
  margin: 0 auto 20px auto; }
  .successful-visual .title {
    text-transform: uppercase; }
    .successful-visual .title__small {
      margin-top: 15px;
      font-size: 20px;
      line-height: 20px;
      font-weight: 500; }
    .successful-visual .title__large {
      font-weight: 600;
      font-size: 72px;
      line-height: 72px;
      background: #8bc242;
      background: linear-gradient(to right, #8bc242 0%, #2177b9 100%);
      -webkit-background-clip: text;
      /* stylelint-disable-line */
      -webkit-text-fill-color: transparent;
      /* stylelint-disable-line */ }

.u-height-100 {
  height: 100%; }

.u-shadow {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25); }

.u-radius {
  border-radius: 4px; }

.u-txt-s {
  font-size: 16px; }

.u-txt-xs {
  font-size: 14px; }

.u-txt-xxs {
  font-size: 12px;
  line-height: 16px; }

.u-color-gold {
  color: #d6af36; }

.u-color-silver {
  color: #a7a7ad; }

.u-color-bronze {
  color: #a77044; }

.u-nowrap {
  white-space: nowrap; }

/**
 * e.g. Google font
 *
 * @optional One font
 * @import url('https://fonts.googleapis.com/css?family=Open+Sans');
 *
 * @optional Multiple fonts
 * @import url('https://fonts.googleapis.com/css?family=Open+Sans|Source+Sans+Pro:600');
 */

/**
 * File with settings. A setting is a scss variable for example.
 * All variables are made available by webpack (sass-resource-loader). There is no need to import this file in every scss or vue file.
 *
 * WARNING:
 * Do not include anything that will be actually rendered in CSS, because it will be added to every imported SASS file.
 */
/**
 * iotaCSS Core Settings
 * @url https://www.iotacss.com/docs/settings/core/
 */
.top {
  border-bottom: 1px solid #e5e5e5;
  height: 60px;
  text-align: right;
}
.top__link {
    padding: 0 10px;
    margin: 0;
    line-height: 60px;
    color: #2177b9;
    border-right: 1px solid #e5e5e5;
}
.top__link:last-of-type {
      border-right: 0 none;
}
.top .languages .language {
    border-right: 1px solid #e5e5e5;
    padding: 0 5px;
    margin: 0;
}
.top .languages .language:last-of-type {
      border-right: 0 none;
}
.top .languages .language--active {
      text-decoration: underline;
}
.popover__item {
  line-height: 28px;
  display: block;
}
.header {
  height: 106px;
}
.header .logo {
    width: 170px;
}
@media only screen and (max-width: 640px) {
.top {
    font-size: 14px;
    white-space: nowrap;
}
.top__link {
      padding: 0 5px;
}
.top .el-button {
      padding: 12px;
      font-size: 14px;
}
}


/*# sourceMappingURL=app.fde7473833e3c48f4202.min.css.map*/