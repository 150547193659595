/**
 * File with settings. A setting is a scss variable for example.
 * All variables are made available by webpack (sass-resource-loader). There is no need to import this file in every scss or vue file.
 *
 * WARNING:
 * Do not include anything that will be actually rendered in CSS, because it will be added to every imported SASS file.
 */
/**
 * iotaCSS Core Settings
 * @url https://www.iotacss.com/docs/settings/core/
 */
.top {
  border-bottom: 1px solid #e5e5e5;
  height: 60px;
  text-align: right;
}
.top__link {
    padding: 0 10px;
    margin: 0;
    line-height: 60px;
    color: #2177b9;
    border-right: 1px solid #e5e5e5;
}
.top__link:last-of-type {
      border-right: 0 none;
}
.top .languages .language {
    border-right: 1px solid #e5e5e5;
    padding: 0 5px;
    margin: 0;
}
.top .languages .language:last-of-type {
      border-right: 0 none;
}
.top .languages .language--active {
      text-decoration: underline;
}
.popover__item {
  line-height: 28px;
  display: block;
}
.header {
  height: 106px;
}
.header .logo {
    width: 170px;
}
@media only screen and (max-width: 640px) {
.top {
    font-size: 14px;
    white-space: nowrap;
}
.top__link {
      padding: 0 5px;
}
.top .el-button {
      padding: 12px;
      font-size: 14px;
}
}
